/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
// @import "../node_modules/@fullcalendar/daygrid/main.min.css";
// @import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "assets/demo/flags/flags.css";



.mxRubberband {
	// z-index: 1000000;
	position: absolute;
	overflow: hidden;
	border-style: solid;
	border-width: 1px;
	border-color: #0494c5;
	background: #0077FF;
}

.report-container {
    height: 77.5vh;
    // margin: 8px auto;
    width: 100%;
	border: none;
}
iframe {
	border: none !important;
}
